import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Link } from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import { Box, Typography } from "@material-ui/core";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page

import NewestSection from "./Sections/NewestSection.jsx";
import AboutTextLeft from "./Sections/AboutTextLeft.jsx";

import { config } from "ddx.config.js";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={config.landing.mainImage}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className={classes.title}>{config.title}</h1>
                <h4 className={classes.subtitle}>{config.landing.text}</h4>
                <Typography variant="caption" color="secondary">
                  {config.landing.disclosure}
                </Typography>
                <br />
                <Box display="flex" flexDirection="row">
                  {config.landing.button.map((b, i) => (
                    <Box p={1} key={i}>
                      <Link
                        rel="noopener noreferrer"
                        className={classes.buttonLink}
                        to={b.to}
                      >
                        <Button color="dd" size="md">
                          {b.text}
                        </Button>
                      </Link>
                    </Box>
                  ))}
                </Box>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container} style={{ paddingBottom: "10px" }}>
            <NewestSection></NewestSection>
            <AboutTextLeft></AboutTextLeft>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle, { withTheme: true })(LandingPage);
