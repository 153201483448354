import React from "react";
import { Logger } from "loglevel-colored-prefix";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";

const logger = new Logger("debug");
const log = logger.getLogger("Image.tmpl_1AC73C98");

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

class Image extends React.Component {
  state = {
    update: false,
    value: ""
  };

  handleChange = name => event => {
    this.setState({ value: event.target.value }, this.updateParent(name));
  };

  updateParent = name => {
    this.setState({ update: !this.state.update }, () => {
      const payload = {
        update: this.state.update
      };
      payload[name] = this.state.value;
      log.info(`Updating parent`);
      log.debug(payload);
      this.props.onChangePayload(payload);
    });
  };

  renderImageField = () => {
    const { classes, label, existing } = this.props;
    return (
      <TextField
        id="image"
        label={label || "Image - IPFS address"}
        defaultValue={existing}
        fullWidth
        onChange={this.handleChange("image")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderThumbField = () => {
    const { classes, label, existing } = this.props;
    return (
      <TextField
        id="thumb"
        label={label || "Thumbnail - IPFS address"}
        fullWidth
        defaultValue={existing}
        onChange={this.handleChange("thumb")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderOipRefField = () => {
    const { classes, checking, label, existing, oipRefError } = this.props;
    log.debug(`oipRefError`);
    log.debug(oipRefError);
    if (oipRefError === null) {
      log.error(`oipref of image not found: ${this.state.oipref}`);
    }
    return (
      <TextField
        id="oipref"
        label={label || "Existing Image OipRef"}
        error={!oipRefError}
        helperText={
          checking
            ? "checking oipref"
            : !oipRefError && "OipRef not a oipRefError"
        }
        defaultValue={existing}
        noWrap
        fullWidth
        onChange={this.handleChange("oipref")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {checking ? <CircularProgress color="inherit" size={10} /> : null}
            </InputAdornment>
          ),
          style: {
            fontSize: "10px",
            overflowWrap: "break-word",
            fontStyle: "italic",
            fontFamily: "monospace"
          }
        }}
        InputLabelProps={{
          className: classes.input,
          shrink: true,
        }}
      />
    );
  };

  render() {
    const { component } = this.props;
    switch (component) {
      case "image":
        return this.renderImageField();
      case "thumb":
        return this.renderThumbField();
      case "oipref":
        return this.renderOipRefField();
    }
  }
}

Image.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePayload: PropTypes.func,
  component: PropTypes.string,
  oipRefError: PropTypes.bool,
  existing: PropTypes.string,
  checking: PropTypes.bool,
  label: PropTypes.string
};

export default withStyles(styles)(Image);
