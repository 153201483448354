import { container, title } from "assets/jss/material-kit-react.jsx";

const cardStyle = {
  section: {
    padding: "70px 0"
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  media: {
    width: "50px",
    height: "50px"
  },
  cardLink: {
    display: "block",
    width: "100%"
  },
  bullet: {
    // "background-color": "red",
    // display: "flex",
    height: "100%"
  }
};

export default cardStyle;
