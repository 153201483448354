import React from "react";
import { Wallet } from "@oipwg/hdmw";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  SvgIcon,
  TextField
} from "@material-ui/core";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";
import { MnemonicsStoreConsumer } from "../../MnemonicsStore.jsx";
import { config } from "ddx.config.js";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      search: "",
      mnemonics: "",
      isValidMnemonics: false,
      showPass: false
    };
  }

  togglePass = () => {
    this.setState({ showPass: !this.state.showPass });
  };

  setMnemonics = mnemonics => {
    this.setState({ mnemonics }, this.checkMnemonics);
  };

  generateMnemonics = () => {
    const wallet = new Wallet(undefined, { discover: false });
    const mnemonics = wallet.getMnemonic();
    this.setMnemonics(mnemonics, this.checkMnemonics);
  };

  checkMnemonics = async () => {
    const mn = this.state.mnemonics.replace(/ {2,}/g, " ").split(" ");
    const decision =
      mn.length >= 12 && mn.reduce((a, b) => b.length >= 3 && a, true);
    console.log(mn);
    console.log(decision);
    this.setState({ isValidMnemonics: decision });
  };

  loadKeys = () => {
    const context = this.context;
    context.loadKeys(this.state.mnemonics);
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    const mnemonics = this.context.mnemonics;
    this.setState({ mnemonics });
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      300
    );
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={config.landing.mainImage}>
          <div className={classes.container}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <CardHeader color="dd" className={classes.cardHeader}>
                    <h3>Keys</h3>
                  </CardHeader>
                  <CardBody>
                    <p>
                      Every record on {config.title} must be signed by FLO
                      cryptographic keys.
                    </p>
                    <h5>
                      Enter a mnemonic to build your cryptographic keys or
                      select a new one.
                    </h5>

                    <Box
                      display="flex"
                      width={1}
                      alignItems="center"
                      alignContent="center"
                      p={0}
                      flexDirection="row"
                    >
                      <Box flexGrow={1}>
                        <MnemonicsStoreConsumer>
                          {({ walletState }) => (
                            <TextField
                              error={walletState === 3}
                              helperText={
                                walletState === 3
                                  ? "Something is wrong with your mnemonics, try another one."
                                  : ""
                              }
                              type={this.state.showPass ? "text" : "password"}
                              label="Enter a 12 words mnemonic to enable the load button"
                              variant="standard"
                              size="small"
                              fontSize="small"
                              fullWidth
                              id="mnemonics-input"
                              value={this.state.mnemonics}
                              onChange={e => this.setMnemonics(e.target.value)}
                              color="secondary"
                              InputLabelProps={{
                                className: classes.input,
                                shrink: true
                              }}
                            />
                          )}
                        </MnemonicsStoreConsumer>
                      </Box>
                      <Box flex flexDirection="row">
                        {!this.state.showPass ? (
                          <Tooltip title="view">
                            <IconButton
                              size="small"
                              onClick={() =>
                                this.togglePass(this.state.showPass)
                              }
                            >
                              <SvgIcon fontSize="small">
                                <path
                                  fill="currentColor"
                                  d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
                                />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="hide">
                            <IconButton
                              size="small"
                              onClick={() =>
                                this.togglePass(this.state.showPass)
                              }
                            >
                              <SvgIcon fontSize="small">
                                <path
                                  fill="currentColor"
                                  d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"
                                />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Generate Mnemonic">
                          <IconButton
                            size="small"
                            onClick={this.generateMnemonics}
                          >
                            <SvgIcon fontSize="small">
                              <path
                                fill="currentColor"
                                d="M12.63,2C18.16,2 22.64,6.5 22.64,12C22.64,17.5 18.16,22 12.63,22C9.12,22 6.05,20.18 4.26,17.43L5.84,16.18C7.25,18.47 9.76,20 12.64,20A8,8 0 0,0 20.64,12A8,8 0 0,0 12.64,4C8.56,4 5.2,7.06 4.71,11H7.47L3.73,14.73L0,11H2.69C3.19,5.95 7.45,2 12.63,2M15.59,10.24C16.09,10.25 16.5,10.65 16.5,11.16V15.77C16.5,16.27 16.09,16.69 15.58,16.69H10.05C9.54,16.69 9.13,16.27 9.13,15.77V11.16C9.13,10.65 9.54,10.25 10.04,10.24V9.23C10.04,7.7 11.29,6.46 12.81,6.46C14.34,6.46 15.59,7.7 15.59,9.23V10.24M12.81,7.86C12.06,7.86 11.44,8.47 11.44,9.23V10.24H14.19V9.23C14.19,8.47 13.57,7.86 12.81,7.86Z"
                              />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      className={classNames(
                        classes.button,
                        classes.info,
                        classes.sm
                      )}
                      disabled={!this.state.isValidMnemonics}
                      variant="contained"
                      onClick={async () => {
                        console.log("button");
                        this.loadKeys();
                      }}
                    >
                      Load keys
                    </Button>
                  </CardFooter>
                  <p className="login-helper">
                    If you don't know what a mnemonic is, or want to know more
                    about this wallet, see our About Page.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <Footer />
      </div>
    );
  }
}

LoginPage.contextType = MnemonicsStoreConsumer;

export default withStyles(loginPageStyle)(LoginPage);
