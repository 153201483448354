/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.jsx";

import { config } from "ddx.config.js";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      {config.headerLinks.items.map((b, i) => (
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            size="sm"
          >
            <Link
              className={classes.navLink} to={b.to}
            >
              {b.name}
            </Link>
          </Button>
        </ListItem>
      ))}
    </List>
  );
}



export default withStyles(headerLinksStyle)(HeaderLinks);
