import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  aboutp: {
    fontSize: "18px",
    lineHeight: "1,5",
    marginBottom: "30px"
  },

  h3bold: {
    fontWeight: "500",
    marginBottom: "20px"
  },

  imgabout: {
    maxWidth: "100%",
    borderRadius: "10px"
  },

  aboutfull: {
    background: "#fafafa",
    padding: "20px",
    borderRadius: "3px"
  }
});

function FullWidthGrid(props) {
  const { classes, content } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={24} className={classes.aboutfull}>
        <Grid item xs={12}>
          <h2>{content.title}</h2>
          <div>
            {content.text.map((text, i) => (
              <p key={i} className={classes.aboutp}>
                {text}
              </p>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default withStyles(styles)(FullWidthGrid);
