import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import recordPageStyle from "assets/jss/material-kit-react/views/recordPage.jsx";
import { Box, Grid } from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { Snackbar } from "@material-ui/core";

const api = new OipApi(config.daemonApiUrl);
const dashboardRoutes = [];

class RecordPage extends React.Component {
  constructor(data) {
    super();
    this.oipref = data.match.params.id;
    this.state = {
      name: "",
      description: "",
      imgRef: require("assets/img/ddx-placeHolder.png"),
      location: "",
      tagList: [],
      noteList: [],
      parentOrganizationList: [],
      error: false,
      errorMessage: "we cannot find parts of this record."
    };
  }

  checkData(id, data) {
    if (data.results.length === 0) {
      return this.setState({ error: true }, () => {
        return false;
      });
    }
    return true;
  }

  basicHandlerOfOipRef = id => {
    console.log(`Receiving: ${id}`);
    return api.getRecord(id).then(data => {
      console.log(`Data received: ${JSON.stringify(data)}`);
      if (!this.checkData(id, data)) {
        return {
          id,
          name: ""
        };
      }
      const name =
        data.results[0].record.details[config.cardInfo.name.tmpl][
          config.cardInfo.name.name
        ];
      return {
        id,
        name
      };
    });
  };

  peopleHandlerOfOipRef = id => {
    console.log(`Receiving: ${id}`);
    return api.getRecord(id).then(data => {
      console.log(`Data received: ${data}`);
      if (!this.checkData(id, data)) {
        return {
          id,
          name: ""
        };
      }
      let name =
        data.results[0].record.details[config.cardInfo.name.tmpl][
          config.cardInfo.name.name
        ];

      if (data.results[0].record.details[config.cardInfo.surname.tmpl]) {
        name +=
          " " +
          data.results[0].record.details[config.cardInfo.surname.tmpl][
            config.cardInfo.surname.name
          ];
      }
      return {
        id,
        name
      };
    });
  };

  handleOipRefs = (type, ids, handler) => {
    const items = ids.map(handler);

    Promise.all(items).then(names => {
      this.setState({
        [type]: names
      });
    });
  };

  componentDidMount() {
    api.getRecord(this.oipref).then(data => {
      if (!this.checkData(this.oipref, data)) {
        return {
          id: this.oipref,
          name: ""
        };
      }
      const recordInfo = data.results[0].record.details;
      if (recordInfo) {
        console.log(recordInfo);
        const avatarId =
          recordInfo[config.cardInfo.avatarRecord.tmpl][
            config.cardInfo.avatarRecord.name
          ];

        if (avatarId) {
          console.log(`avatarId: ${avatarId}`);
          const callAvatar = api.getRecord(avatarId);
          callAvatar.then(avatar => {
            const address =
              avatar.results[0].record.details[config.imageHandler.main.tmpl][
                config.imageHandler.main.name
              ];
            this.setState({
              imgRef: `${config.ipfs.apiUrl}${address}`
            });
          });
        }

        const name =
          recordInfo[config.cardInfo.name.tmpl][config.cardInfo.name.name];

        const description =
          recordInfo[config.cardInfo.description.tmpl][
            config.cardInfo.description.name
          ];

        const location =
          recordInfo[config.organizationHandler.location.tmpl][
            config.organizationHandler.location.name
          ];

        /*         const parentOrganizationOipRef =
          recordInfo[config.organizationHandler.parentOrganization.tmpl][
            config.organizationHandler.parentOrganization.name
          ];

        if (parentOrganizationOipRef) {
          this.basicHandlerOfOipRef(parentOrganizationOipRef).then(org => {
            this.setState({ parentOrganization: org });
          });
        } */

        const tagList =
          recordInfo[config.cardInfo.tagList.tmpl][
            config.cardInfo.tagList.name
          ] || [];

        const noteList =
          recordInfo[config.cardInfo.noteList.tmpl][
            config.cardInfo.noteList.name
          ] || [];

        const parentOrganizationListOipRef =
          recordInfo[config.organizationHandler.parentOrganizationList.tmpl][
            config.organizationHandler.parentOrganizationList.name
          ];

        if (parentOrganizationListOipRef) {
          this.handleOipRefs(
            "parentOrganizationList",
            parentOrganizationListOipRef,
            this.basicHandlerOfOipRef
          );
        }

        /*         if (legacyIdsOipRef) {
          const legacyIds = legacyIdsOipRef.map(this.basicHandlerOfOipRef);

          Promise.all(legacyIds).then(names => {
            this.setState({
              legacyIds: names
            });
          });
        } */

        /*         if (preparedFromOipRef) {
          const preparedFrom = preparedFromOipRef.map(
            this.basicHandlerOfOipRef
          );

          Promise.all(preparedFrom).then(names => {
            this.setState({
              preparedFrom: names
            });
          });
        } */

        this.setState({
          name,
          description,
          location,
          noteList,
          tagList
        });
      }
    });
  }

  render() {
    console.log(this.oipref);
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax className={classes.small}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>{this.state.name}</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          style={{ width: "100%" }}
        >
          <SnackbarContent
            message={
              <span>
                <b>Warning</b> {this.state.errorMessage}
              </span>
            }
            color="danger"
            close
            icon="info_outline"
          />
        </Snackbar>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <Box flex={1} flexDirection={"column"} spacing={1} width="1/2">
                <h3
                  className={classes.title}
                  style={{
                    marginTop: "0px",
                    marginBottom: "-10px"
                  }}
                >
                  {this.state.name}
                </h3>
                <Typography
                  style={{
                    fontSize: "8px",
                    overflowWrap: "break-word",
                    fontStyle: "italic"
                  }}
                  className={classes.root}
                >
                  <Link
                    href={`${config.floExplorer.url}/tx/${this.oipref}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {this.oipref}
                  </Link>
                </Typography>
                <p></p>

                <label className={classes.meta}>Description: </label>
                <p>{this.state.description}</p>
                <label component="label" className={classes.meta}>
                  Location:{" "}
                </label>
                <Typography style={{ display: "inline-block" }} variant="body2">
                  {this.state.location}
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Box display="flex" flexDirection="column" width={1 / 2}>
                    <label className={classes.meta}>
                      Parent Organizations:{" "}
                    </label>
                    {this.state.parentOrganizationList.map((org, key) => {
                      return (
                        <Box px={1} key={key}>
                          <Link
                            href={`${org.id}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            key={key}
                            variant="body2"
                          >
                            - {org.name}
                          </Link>
                        </Box>
                      );
                    })}
                    <label className={classes.meta}>Notes: </label>
                    <Box p={1}>
                      {this.state.noteList.map((step, key) => {
                        return (
                          <Box py={1} key={key}>
                            <Typography
                              variant="body2"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              - {step}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    <label className={classes.meta}>Tags: </label>
                    <Box p={1}>
                      <Typography variant="body2">
                        {this.state.tagList.map((tag, key) => {
                          return (
                            <span key={key}>
                              {tag}
                              {key === this.state.tagList.length - 1
                                ? "."
                                : ", "}
                            </span>
                          );
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Grid item xs={12} sm={6} md={8}></Grid>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src={this.state.imgRef}
                  alt="avatar"
                  style={{ maxWidth: "250px" }}
                ></img>
              </Box>
            </Box>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(recordPageStyle)(RecordPage);
