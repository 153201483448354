import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { config } from "../../../ddx.config";

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  aboutp: {
    fontSize: "18px",
    lineHeight: "1,5",
    marginBottom: "30px"
  },

  h3bold: {
    fontWeight: "500",
    marginBottom: "20px"
  },

  imgabout: {
    maxWidth: "100%",
    borderRadius: "10px"
  },

  aboutleft: {
    margin: "50px 0px"
  }
});

function FullWidthGrid(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.aboutleft}>
        <Grid item xs={12} sm={6}>
          <h3 className={classes.h3bold}>{config.landing.aboutTitle}</h3>
          <p className={classes.aboutp}>{config.landing.aboutShortText}</p>
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            href="/about"
          >
            Learn More
          </Button> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={config.landing.aboutImage}
            alt="..."
            className={classes.imgabout}
          />{" "}
        </Grid>
      </Grid>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullWidthGrid);
