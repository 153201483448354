import React from "react";
import { Wallet } from "@oipwg/hdmw";

export const MnemonicsContext = React.createContext();

class MnemonicsStoreProvider extends React.Component {
  state = {
    wallet: null,
    mnemonics: "",
    walletState: 0
  };

  loadKeys = mnemonics => {
    this.setState({ mnemonics, walletState: 1, wallet: undefined }, () => {
      setTimeout(() => {
        try {
          const wallet = new Wallet(this.state.mnemonics, { discover: false });
          this.setState({ wallet, walletState: 2 });
        } catch {
          this.setState({ walletState: 3 });
        }
      }, 100);
    });
  };

  render() {
    // Pass down the state and the onToggleLight action
    return (
      <MnemonicsContext.Provider
        value={{
          ...this.state,
          loadKeys: this.loadKeys
        }}
      >
        {this.props.children}
      </MnemonicsContext.Provider>
    );
  }
}

const MnemonicsStoreConsumer = MnemonicsContext.Consumer;

export { MnemonicsStoreProvider, MnemonicsStoreConsumer };
