import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Link } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  aboutp: {
    fontSize: "18px",
    lineHeight: "1,5",
    marginBottom: "30px"
  },

  h3bold: {
    fontWeight: "500",
    marginBottom: "20px"
  },

  imgabout: {
    maxWidth: "100%",
    margin: "auto",
    borderRadius: "10px"
  },

  aboutleft: {
    margin: "50px 0px"
  }
});

function FullWidthGrid(props) {
  const { classes, content } = props;

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" className={classes.aboutleft}>
        <Box>
          <h3 className={classes.h3bold}>{content.title}</h3>
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <Box
            p={1}
            display="flex"
            flexShrink={1}
            flexDirection="column"
            width={{ xs: 1, sm: 1 / 2 }}
          >
            <div>
              {content.text.map((text, i) => (
                <p key={i} className={classes.aboutp}>
                  {text}
                </p>
              ))}
            </div>
            <div>
            {content.button && (
                <Link target="_blank" rel="noopener" href={content.button.href}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    {content.button.text}
                  </Button>
                </Link>
              )}
            </div>
          </Box>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            width={{ xs: 1, sm: 1 / 2 }}
          >
            <img src={content.image} alt="..." className={classes.imgabout} />{" "}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default withStyles(styles)(FullWidthGrid);
