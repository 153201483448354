import React from "react";

import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import cardStyle from "assets/jss/material-kit-react/views/componentsSections/cardStyle.jsx";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography
} from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

const api = new OipApi(config.daemonApiUrl);

class SectionCard extends React.Component {
  state = {
    name: "",
    description: "",
    address: "",
    thumbnail: require("assets/img/ddx-placeHolder.png"),
    parentOrganizationListName: [],
    txid: "",
    sig: ""
  };

  componentDidMount() {
    if (this.props.data) {
      console.log(this.props.data);
      const recordInfo = this.props.data.record.details;
      if (recordInfo) {
        const avatarId =
          recordInfo[config.cardInfo.avatarRecord.tmpl][
            config.cardInfo.avatarRecord.name
          ];
        const callAvatar = api.getRecord(avatarId);

        const parentOrganizationList =
          recordInfo[config.organizationHandler.parentOrganizationList.tmpl][
            config.organizationHandler.parentOrganizationList.name
          ];
        console.log(parentOrganizationList);
        let name =
          recordInfo[config.cardInfo.name.tmpl][config.cardInfo.name.name];

        const description =
          recordInfo[config.cardInfo.description.tmpl][
            config.cardInfo.description.name
          ];

        const txid = this.props.data.meta.txid;
        const sig = this.props.data.meta.signed_by;

        this.setState({
          name,
          description,
          parentOrganizationList,
          txid,
          sig
        });

        if (parentOrganizationList) {
          parentOrganizationList.forEach(org => {
            const callPeople = api.getRecord(org);
            callPeople.then(response => {
              console.log(response);
              if (response !== "not found") {
                const name =
                  response.results[0].record.details[config.cardInfo.name.tmpl][
                    config.cardInfo.name.name
                  ];
                /* const surname =
                  response.results[0].record.details[
                    config.cardInfo.surname.tmpl
                  ][config.cardInfo.surname.name]; */
                const fullName = `${name}`; // ${surname}`;
                this.state.parentOrganizationListName.push(fullName);
                console.log(this.state.parentOrganizationListName);
                this.setState({
                  parentOrganizationListName: this.state
                    .parentOrganizationListName
                });
              }
            });
          });
        }
        callAvatar.then(response => {
          if (response !== "not found") {
            const address =
              response.results[0].record.details[
                config.imageHandler.thumbnail.tmpl
              ][config.imageHandler.thumbnail.name];
            this.setState({
              thumbnail: `${config.ipfs.apiUrl}${address}`
            });
          }
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.bullet}>
        <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
          <Box display="flex" flexGrow={1}>
            <Link
              className={classes.cardLink}
              to={"/record/" + this.state.txid}
            >
              <CardActionArea>
                <Box p={1} display="flex" flexGrow={1} width="100%">
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row">
                      <Box pr={1} flexGrow={1}>
                        <Typography
                          // noWrap={true}
                          variant="h5"
                          // style={{ fontStyle: "bold" }}
                          color="textPrimary"
                        >
                          {this.state.name}
                        </Typography>
                      </Box>
                      <Box>
                        <CardMedia
                          component="img"
                          alt="Record Image"
                          className={classes.media}
                          maxHeight="50"
                          width="150"
                          image={this.state.thumbnail}
                          title={this.state.name}
                          href="/record"
                        />
                      </Box>
                    </Box>
                    <Box pt={1}>
                      <Typography
                        // noWrap={true}
                        variant="caption"
                        style={{ overflowWrap: "break-word" }}
                        color="textPrimary"
                      >
                        {this.state.description
                          .split(" ")
                          .slice(0, 20)
                          .join(" ")}
                        ...
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardActionArea>
            </Link>
          </Box>

          <Box
            p={1}
            display="flex"
            flexDirection="column"
            flexShrink={1}
            alignItems="flex-end"
          >
            <Box p={1} alignContent="right">
              <Typography
                // noWrap={true}
                variant="caption"
                style={{ overflowWrap: "break-word" }}
                color="textPrimary"
              >
                {this.state.parentOrganizationListName.join(", ")}
              </Typography>
            </Box>
            <Box display="inline" maxWidth="100%">
              <Typography
                style={{
                  display: "block",
                  fontSize: "8px",
                  overflowWrap: "break-word",
                  fontStyle: "italic",
                  fontFamily: "monospace"
                }}
                color="textPrimary"
              >
                {this.state.txid}
              </Typography>
            </Box>
            <Box display="inline" maxWidth="100%">
              <Typography
                style={{
                  display: "block",
                  fontSize: "8px",
                  overflowWrap: "break-word",
                  fontStyle: "italic",
                  fontFamily: "monospace"
                }}
                color="textPrimary"
              >
                Owned by: {this.state.sig}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }
}

export default withStyles(cardStyle, { withTheme: true })(SectionCard);
