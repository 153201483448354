import { OIP } from "js-oip";
import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { display } from "@material-ui/system";

import {
  Box,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

// "tmpl_636E68FA"

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

class Protocol extends React.Component {
  state = {
    update: true,
    designedByList: [],
    protocolList: [],
    stepList: [],
    deviceList: [],
    softwareList: [],
    materialList: [],
    othersList: [],
    organizationList: [],
    legacyIdList: [],
    designedByListName: [],
    protocolListName: [],
    deviceListName: [],
    softwareListName: [],
    materialListName: [],
    organizationListName: []
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, this.updateParent());
  };

  updateParent = () => {
    this.setState({ update: !this.state.update }, () => {
      const payload = {
        update: this.state.update,
        designedByList: this.state.designedByList,
        protocolList: this.state.protocolList,
        stepList: this.state.stepList,
        deviceList: this.state.deviceList,
        softwareList: this.state.softwareList,
        materialList: this.state.materialList,
        othersList: this.state.othersList,
        organizationList: this.state.organizationList,
        legacyIdList: this.state.legacyIdList
      };
      this.props.onChangePayload(payload);
    });
  };

  handleChangeOipList = (name, checkOip) => event => {
    const input = event.target.value.split("\n");
    while (input.indexOf("") !== -1) {
      const rm = input.indexOf("");
      input.splice(rm, 1);
    }
    this.setState({ [name]: input }, () =>
      checkOip ? this.setNames(name, checkOip) : this.updateParent()
    );
  };

  setNames = async name => {
    console.log("setNames");
    const names = [];
    const ids = this.state[name];
    console.log(ids);
    for (let id of ids) {
      const name = await this.oip2name(id);
      if (name !== "") {
        names.push(name);
      }
    }
    this.setState({ [`${name}Name`]: names }, () => this.updateParent());
  };

  oip2name = id => {
    const oip = new OipApi();
    console.log(id);
    return oip.getRecord(id).then(record => {
      console.log(record);
      if (record.results) {
        if (record.results.length === 1 && record.results[0].meta.txid === id) {
          const name =
            record.results[0].record.details[config.cardInfo.name.tmpl][
              config.cardInfo.name.name
            ];
          console.log(`record: ${JSON.stringify(name)}`);
          return name;
        }
        console.log(`no record found!`);
        return "";
      }
      console.log("not valid id");
      return "";
    });
  };

  renderValue = (component, label) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChange(component)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueList = (component, label, checkOip = true) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChangeOipList(component, checkOip)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueListDisplay = (component, label, display) => {
    return (
      <Box display="flex" width={1} flexGrow={1} flexDirection="row">
        <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
          {display.map((d, i) => (
            <Box key={i}>
              <Typography variant="caption">{d}</Typography>
            </Box>
          ))}
        </Box>
        <Box p={1} width={3 / 4} flexGrow={1}>
          {this.renderValueList(component, label)}
        </Box>
      </Box>
    );
  };

  renderDefault = () => {
    return (
      <Box
        justifyContent="center"
        flexDirection={"column"}
        display="flex"
        flexGrow={1}
        width={1}
      >
        <Box display="flex" width={1} flexGrow={1} flexDirection="row">
          <Box p={1} width={3 / 4} flexGrow={1}>
            {this.renderValueList("stepList", "List of steps", false)}
          </Box>
        </Box>
        <Box display="flex" flexDirection={"column"} width={1}>
          {this.renderValueListDisplay(
            "designedByList",
            "Designed by",
            this.state.designedByListName
          )}
          {this.renderValueListDisplay(
            "deviceList",
            "List of devices",
            this.state.deviceListName
          )}
          {this.renderValueListDisplay(
            "protocolList",
            "List of other protocols",
            this.state.protocolListName
          )}
          {this.renderValueListDisplay(
            "softwareList",
            "List of software",
            this.state.softwareListName
          )}
          {this.renderValueListDisplay(
            "materialList",
            "List of materials",
            this.state.materialListName
          )}
          {this.renderValueListDisplay(
            "organizationList",
            "List of organizations",
            this.state.organizationListName
          )}
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("othersList", "Others", false)}
            </Box>
          </Box>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("legacyIdList", "Legacy Ids", false)}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    const { component } = this.props;
    switch (component) {
      case "designedByList":
        return this.renderValueList(component);
      case "stepList":
        return this.renderValueList(component, null, false);
      case "protocolsList":
        return this.renderValueList(component);
      case "deviceList":
        return this.renderValueList(component);
      case "softwareList":
        return this.renderValueList(component);
      case "materialList":
        return this.renderValueList(component);
      case "othersList":
        return this.renderValueList(component, null, false);
      case "organizationList":
        return this.renderValueList(component);
      case "legacyIdList":
        return this.renderValueList(component);

      default:
        return this.renderDefault();
    }
  }
}

Protocol.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePayload: PropTypes.func,
  component: PropTypes.string,
  label: PropTypes.string
};

export default withStyles(styles)(Protocol);
