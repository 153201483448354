import axios from "axios";
import { Logger } from "loglevel-colored-prefix";
import { config } from "../ddx.config";

class OipApi {
  constructor(loglevel = "debug") {
    this.base = config.oip.daemonApiUrl;
    this.log = new Logger(loglevel);
  }

  getRecord(id) {
    const log = this.log.getLogger("OipApi::getRecord");
    const url = `${config.oip.daemonApiUrl}record/get/${id}`;
    log.debug(`calling URL: ${url}`);
    return axios
      .get(url)
      .then(res => res.data)
      .catch(() => "not found");
  }

  getRecords(
    searchTerms = "",
    page = 1,
    perPage = config.oip.perPage,
    template = config.oip.baseTemplate,
    filterByAddress = true
  ) {
    const log = this.log.getLogger("OipApi::getRecords");
    const searchForTemplateBase = `_exists_:record.details.${template}`;
    let url = `${config.oip.daemonApiUrl}record/search?limit=${perPage}&page=${page}&q=${searchForTemplateBase}`;
    const moreTemplates = config.oip.requiredTemplates.map(
      template => `_exists_:record.details.${template}`
    );
    if (moreTemplates.length) {
      url += ` AND ${moreTemplates.join(" AND ")}`;
    }
    const filterByAddresses = config.oip.addressesWhiteList.map(
      address => `meta.signed_by:${address}`
    );
    if (filterByAddress && filterByAddresses.length) {
      url += ` AND (${filterByAddresses.join(" OR ")})`;
    }
    if (searchTerms !== "") {
      url += ` AND ${searchTerms}`;
    }
    log.debug(`calling URL: ${url}`);
    return axios.get(url).then(res => res.data);
  }

  getRecordsByAddress(
    address = "",
    page = 1,
    perPage = config.oip.perPage,
    template = config.oip.baseTemplate
  ) {
    const log = this.log.getLogger("OipApi::getRecords");
    const searchForTemplateBase = `_exists_:record.details.${template}`;
    let url = `${config.oip.daemonApiUrl}record/search?limit=${perPage}&page=${page}&q=${searchForTemplateBase}`;
    const moreTemplates = config.oip.requiredTemplates.map(
      template => `_exists_:record.details.${template}`
    );
    if (moreTemplates.length) {
      url += ` AND ${moreTemplates.join(" AND ")}`;
    }
    const filterByAddress = `meta.signed_by:${address}`;
    url += ` AND ${filterByAddress}`;

    log.debug(`calling URL: ${url}`);
    const results = axios.get(url).then(res => res.data);
    log.debug(results);
    return results;
  }
}

export { OipApi };
