import { OIP } from "js-oip";
import _ from "lodash";
import React from "react";
import { Logger } from "loglevel-colored-prefix";

import { Link } from "react-router-dom";

import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";

import { Basic, Image, Organization } from "components/ddx/ddx";

import { Pagination } from "@material-ui/lab";

import { OipApi } from "oip/OipApi";
import { Publisher } from "oip/Publisher";
import { config } from "ddx.config.js";
import { MnemonicsStoreConsumer } from "../../../MnemonicsStore.jsx";

const logger = new Logger("INFO");
const log = logger.getLogger("AddRecords::SectionForm");

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  spinnerImg: {
    display: "flex",
    width: "125px",
    height: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  spinnerThumb: {
    display: "flex",
    width: "75px",
    height: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

const defaultImage = require("assets/img/ddx-placeHolder.png");

class SectionForm extends React.Component {
  state = {
    basicPayload: {},
    imagePayload: {},
    organizationPayload: {},
    addresses: [],
    addrDisp: [],
    selectedAddress: "",
    tablePage: 1,
    openConfirmation: false,
    avatarOipRef: "",
    personOipRef: "",
    receiptDialog: 0,
    ipfsSwitch: true,
    displayIpfsImg: "",
    displayIpfsThumb: "",
    displayIpfsImgLoaded: true,
    displayIpfsThumbLoaded: true,
    avatarOipRefError: true,
    avatarOipRefChecking: false,
    donate: config.tip.use,
    donateCheck: config.tip.defaultCheck,
    donationReceipt: ""
  };

  handleBasicPayload = payload => {
    const basicPayload = { ...this.state.basicPayload, ...payload };
    log.debug("basic");
    log.debug(basicPayload);
    this.setState({ basicPayload });
  };

  handleImagePayload = payload => {
    const imagePayload = { ...this.state.imagePayload, ...payload };
    log.debug("image");
    log.debug(imagePayload);
    payload.hasOwnProperty("image") &&
      this.setState({ displayIpfsImgLoaded: false });
    payload.hasOwnProperty("thumb") &&
      this.setState({ displayIpfsThumbLoaded: false });
    this.setState(
      { imagePayload },
      () => !this.state.ipfsSwitch && this.handleAvatarOipRef()
    );
  };

  handleAvatarOipRef = () => {
    log.info(`handling avatar: ${this.state.imagePayload.oipref}`);
    const oip = new OipApi();
    this.setState({ avatarOipRefChecking: true }, () => {
      if (this.state.imagePayload.oipref !== "") {
        const callAvatar = oip.getRecord(this.state.imagePayload.oipref);
        callAvatar.then(response => {
          log.debug(response);
          if (response === "not found") {
            this.setState({
              displayIpfsImg: "",
              displayIpfsThumb: "",
              avatarOipRefError: false,
              avatarOipRefChecking: false
            });
            return;
          }
          if (response.count === 0) {
            this.setState({
              displayIpfsImg: "",
              displayIpfsThumb: "",
              avatarOipRefError: false,
              avatarOipRefChecking: false
            });
            return;
          }
          const avatars = response.results.filter(r =>
            r.record.details.hasOwnProperty(config.imageHandler.main.tmpl)
          );
          log.debug(avatars);
          const imgAddress =
            avatars[0].record.details[config.imageHandler.main.tmpl][
              config.imageHandler.main.name
            ];
          const thumbAddress =
            avatars[0].record.details[config.imageHandler.main.tmpl][
              config.imageHandler.thumbnail.name
            ];
          this.setState({
            displayIpfsImg: `${config.ipfs.apiUrl}${imgAddress}`,
            displayIpfsThumb: `${config.ipfs.apiUrl}${thumbAddress}`,
            avatarOipRefError: true,
            avatarOipRefChecking: false,
            displayIpfsImgLoaded: false,
            displayIpfsThumbLoaded: false
          });
        });
      } else {
        this.setState({
          displayIpfsImg: "",
          displayIpfsThumb: "",
          avatarOipRefError: true,
          avatarOipRefChecking: false
        });
      }
    });
  };

  handleProtocolPayload = payload => {
    const protocolPayload = { ...this.state.protocolPayload, ...payload };
    log.debug("protocol");
    log.debug(protocolPayload);
    this.setState({ protocolPayload });
  };

  handleOrganizationPayload = payload => {
    const organizationPayload = {
      ...this.state.organizationPayload,
      ...payload
    };
    log.debug("organization");
    log.debug(organizationPayload);
    this.setState({ organizationPayload });
  };

  handleOnLoadImg = () => {
    this.setState({ displayIpfsImgLoaded: true });
  };

  handleOnLoadThumb = () => {
    this.setState({ displayIpfsThumbLoaded: true });
  };

  componentDidMount = () => {
    const value = this.context;
    if (value.wallet !== null) {
      this.getAddresses();
    }
  };

  selectAddress = event => {
    log.debug(this.state);
    log.debug(event.target.value);
    this.setState({ selectedAddress: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  changePage = (event, value) => {
    const p = value;
    log.debug(p);
    this.setState({ tablePage: p }, this.setDisplayAddresses);
  };

  onError = err => {
    log.error(err);
    throw new Error(err);
  };

  getNumOfRecords = async address => {
    const addrDisp = this.state.addrDisp;
    const item = addrDisp.find(a => a.pub === address);
    try {
      if (item.numRec === "fetching...") {
        const oip = new OipApi();
        const records = await oip.getRecordsByAddress(address);
        item.numRec = records.total;
        log.debug(`found records of ${address}: ${item.numRec}`);
        this.setState({ addrDisp });
      }
    } catch (err) {
      this.onError(err);
    }
  };

  getAddresses = async () => {
    const value = this.context;
    if (value.wallet !== null) {
      let myWallet = value.wallet;
      const flo = myWallet.getCoin("flo");
      const paymentReceiverAccount = flo.getAccount(0);
      const EXTERNAL_CHAIN = 0;
      await paymentReceiverAccount.getBalance({ discover: true });
      const addressesWithBalance = paymentReceiverAccount.getAddresses(
        EXTERNAL_CHAIN
      );
      await this.setState(
        { addresses: addressesWithBalance },
        this.setDisplayAddresses
      );
    }
  };

  setDisplayAddresses = () => {
    const info = this.state.addresses
      .slice((this.state.tablePage - 1) * 5, (this.state.tablePage - 1) * 5 + 5)
      .map(addr => {
        const pub = addr.getPublicAddress();
        const bal = addr.getBalance();
        const numRec = "fetching...";
        /*         ? addr.numRec
        : "fetching records..." */
        return {
          pub,
          bal,
          numRec
        };
      });
    this.setState({ addrDisp: info }, () => {
      this.state.addrDisp.forEach(a => this.getNumOfRecords(a.pub));
    });

    return;
  };

  publish = async () => {
    try {
      const addr = this.state.selectedAddress;
      const addrInfo = this.state.addresses.find(
        a => a.getPublicAddress() === addr
      );
      const wif = addrInfo.getPrivateAddress();
      log.debug(`Gotta a wif`);
      const pub = new Publisher(wif, "mainnet");
      log.debug(this.state.imagePayload);
      if (!_.isEmpty(this.state.imagePayload)) {
        log.info(`Found information about image. Publishing that first`);
        if (this.state.ipfsSwitch) {
          const img = await pub.image(
            this.state.imagePayload,
            this.state.basicPayload.name
          );
          log.debug(`Img protobuf: ${img}`);
          this.state.imgOipRef = await this.broadcast(img, wif);
        } else {
          this.state.imgOipRef = this.state.imagePayload.oipref;
        }
      }
      log.debug(`Oipref from image: ${this.state.imgOipRef}`);
      const msg = await pub.organization({
        basic: {
          ...this.state.basicPayload,
          avatar: this.state.imgOipRef
        },
        organization: this.state.organizationPayload
      });

      const rec = await this.broadcast(msg, wif);
      if (this.state.donateCheck && this.state.donate) {
        log.debug(`Next up: tipping`);
        try {
          await this.tip();
        } catch (err) {
          log.error(err);
          throw err;
        }
      }
      this.setState(
        { personOipRef: rec, avatarOipRef: this.state.imgOipRef },
        () => this.setState({ receiptDialog: 1 })
      );
    } catch {
      this.setState({ receiptDialog: 2 });
    }
  };

  broadcast = async (msg, wif) => {
    const explorerUrl = `${config.floExplorer}/api`;
    const oip = new OIP(wif, this.state.floNetwork, { oipURL: explorerUrl });
    const myWallet = oip.wallet;
    let res;
    if (msg.length > 1040) {
      log.debug("publishing multiparts");
      try {
        res = await oip.publishMultiparts(msg);
      } catch (err) {
        this.onError(err);
      }
    } else {
      log.debug("publishing single part");
      try {
        res = await myWallet.sendDataToChain(msg);
      } catch (err) {
        this.onError(err);
      }
    }
    return res;
  };

  tip = async () => {
    log.debug("let's tip");
    const wallet = this.context.wallet;
    const addr = this.state.selectedAddress;
    const addresses = config.tip.addresses.map(a => a.id);
    const payout = config.tip.suggestedTip / addresses.length;
    const entries = { to: [] };
    addresses.forEach(a => {
      const obj = {};
      obj[a] = payout;
      entries.to.push(obj);
    });
    // const flo = context.wallet.getCoin("flo");
    entries.coin = "flo";
    log.debug(addr);
    // entries.from = addr;
    log.debug(
      `All ready to tip ${payout} for each these addresses: ${addresses}`
    );
    log.debug(JSON.stringify(entries));
    if (wallet !== null) {
      log.debug(`tipping`);
      try {
        log.debug("receipt:");
        const donationReceipt = await wallet.sendPayment(entries);
        log.debug(donationReceipt);
        this.setState({ donationReceipt });
      } catch (err) {
        log.error(err);
        throw new Error(err);
      }
    }
  };

  confirm = () => {
    console.log(this.state);
    this.setState({ openConfirmation: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ openConfirmation: false });
  };

  handleReceipt = n => {
    this.setState({ receiptDialog: n, openConfirmation: false });
  };

  ipfsSwitch = () => {
    this.setState({ ipfsSwitch: !this.state.ipfsSwitch });
  };

  donationSwitch = () => {
    this.setState({ donateCheck: !this.state.donateCheck });
  };

  render() {
    const { classes } = this.props;
    const context = this.context;
    let content;
    let imageInput;

    if (this.state.ipfsSwitch) {
      imageInput = (
        <Box
          display="flex"
          flexGrow={1}
          width={{ xs: 1, sm: 1 / 2 }}
          flexDirection="column"
        >
          <Box p={1} width={1}>
            <Image
              component="image"
              existing={this.state.imagePayload.image}
              onChangePayload={this.handleImagePayload}
            ></Image>
          </Box>
          <Box p={1} width={1}>
            <Image
              component="thumb"
              existing={this.state.imagePayload.thumb}
              onChangePayload={this.handleImagePayload}
            ></Image>
          </Box>
        </Box>
      );
    } else {
      imageInput = (
        <Box p={1} width={1}>
          <Image
            component="oipref"
            existing={this.state.imagePayload.oipref}
            onChangePayload={this.handleImagePayload}
            oipRefError={this.state.avatarOipRefError}
            checking={this.state.avatarOipRefChecking}
          />
        </Box>
      );
    }
    if (context.wallet === null) {
      content = (
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="row"
          xs={12}
        >
          <Grid>
            <Typography displayBlock variant="body1">
              Load a key first:
            </Typography>
          </Grid>
          <Button
            component={Link}
            to={config.headerLinks.keys.to}
            color="transparent"
            size="md"
            linkButton={true}
          >
            <SvgIcon className={classes.flex} htmlColor="#424242">
              <path
                fill="currentColor"
                d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M12,6A3,3 0 0,1 15,9C15,10.31 14.17,11.42 13,11.83V14H15V16H13V18H11V11.83C9.83,11.42 9,10.31 9,9A3,3 0 0,1 12,6M12,8A1,1 0 0,0 11,9A1,1 0 0,0 12,10A1,1 0 0,0 13,9A1,1 0 0,0 12,8Z"
              />
            </SvgIcon>
          </Button>
        </Grid>
      );
    } else {
      content = (
        <Box className={classes.container}>
          <Box width={1}>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <Box p={1} width={{ xs: 1, sm: 3 / 4 }}>
                <Basic
                  label="Nome"
                  component="name"
                  classes={classes}
                  onChangePayload={this.handleBasicPayload}
                />
              </Box>
              <Box p={1} width={{ xs: 1, sm: 1 / 4 }} minWidth="170px">
                <Basic
                  component="date"
                  label="Inauguration date"
                  onChangePayload={this.handleBasicPayload}
                ></Basic>
              </Box>
            </Box>
            <Box p={1} width={1}>
              <Basic
                component="description"
                label="Description"
                onChangePayload={this.handleBasicPayload}
              ></Basic>
            </Box>
          </Box>
          <Organization
            onChangePayload={this.handleOrganizationPayload}
          ></Organization>
          <Box display="flex" flexGrow={1} flexDirection="column">
            <Box
              display="flex"
              flexGrow={1}
              flexDirection="row"
              style={{ width: "100%" }}
            >
              <Box pl={1} display="flex" alignItems="center">
                <Typography variant="body2">Logo</Typography>
              </Box>
              <Box p={1} display="flex">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="caption">IPFS</Typography>
                  </Grid>
                  <Grid>
                    <Switch
                      size="small"
                      onChange={this.ipfsSwitch}
                      color="primary"
                    ></Switch>
                  </Grid>
                  <Grid>
                    <Typography variant="caption">OipRef</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "row" }}
              display="flex"
              flexGrow={1}
              width="100%"
            >
              {imageInput}
              <Box
                justifyContent="center"
                flexGrow={1}
                flexDirection={{ xs: "column", sm: "row" }}
                display="flex"
                width={{ xs: 1, sm: 1 / 2 }}
                alignItems={{ xs: "center", sm: "flex-start" }}
              >
                <Box
                  display="flex"
                  p={1}
                  width={{ xs: 1, sm: 1 / 2 }}
                  mx="auto"
                >
                  <Box flexShrink={1} mx="auto">
                    {
                      <div>
                        <img
                          className={classes.avatar}
                          onLoad={this.handleOnLoadImg}
                          hidden={!this.state.displayIpfsImgLoaded}
                          src={
                            this.state.ipfsSwitch
                              ? this.state.imagePayload.image
                                ? `${config.ipfs.apiUrl}${this.state.imagePayload.image}`
                                : defaultImage
                              : this.state.displayIpfsImg
                              ? this.state.displayIpfsImg
                              : defaultImage
                          }
                          alt="avatar"
                        />
                        <Box
                          className={classes.spinnerImg}
                          style={
                            this.state.displayIpfsImgLoaded
                              ? { display: "none" }
                              : { display: "flex" }
                          }
                          flexGrow={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      </div>
                    }
                  </Box>
                </Box>
                <Box
                  display="flex"
                  p={1}
                  width={{ xs: 1, sm: 1 / 4 }}
                  mx="auto"
                >
                  <Box flexShrink={1} mx="auto">
                    {
                      <div>
                        <img
                          className={classes.thumb}
                          onLoad={this.handleOnLoadThumb}
                          hidden={!this.state.displayIpfsThumbLoaded}
                          src={
                            this.state.ipfsSwitch
                              ? this.state.imagePayload.thumb
                                ? `${config.ipfs.apiUrl}${this.state.imagePayload.thumb}`
                                : defaultImage
                              : this.state.displayIpfsThumb
                              ? this.state.displayIpfsThumb
                              : defaultImage
                          }
                          alt="thumb"
                        />
                        <Box
                          className={classes.spinnerThumb}
                          style={
                            this.state.displayIpfsThumbLoaded
                              ? { display: "none" }
                              : { display: "flex" }
                          }
                          flexGrow={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress color="primary" size={10} />
                        </Box>
                      </div>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box p={1} width={1}>
            <Basic
              component="noteList"
              label="Notes"
              onChangePayload={this.handleBasicPayload}
            ></Basic>
          </Box>
          <Box p={1} width={1}>
            <Basic
              component="tagList"
              label="Tags"
              onChangePayload={this.handleBasicPayload}
            ></Basic>
          </Box>
          <Box display="flex" width={1}>
            <Box p={1} width={1}>
              <Box my={1}>
                <Typography variant="body1">
                  Select a FLO address with balance to sign the records.
                  <Tooltip title="refresh table" placement="right">
                    <IconButton size="small">
                      <SvgIcon fontSize="small" onClick={this.getAddresses}>
                        <path
                          fill="currentColor"
                          d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2M18 11H13L14.81 9.19A3.94 3.94 0 0 0 12 8A4 4 0 1 0 15.86 13H17.91A6 6 0 1 1 12 6A5.91 5.91 0 0 1 16.22 7.78L18 6Z"
                        />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Box>
              <TableContainer className={classes.table}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Select</TableCell>
                      <TableCell align="center">Balance</TableCell>
                      <TableCell align="center">Address</TableCell>
                      <TableCell align="center">
                        Records on {config.title}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody size="small">
                    {this.state.addrDisp.map(addr => {
                      return (
                        <TableRow key={addr.pub}>
                          <TableCell align="right">
                            <Radio
                              value={addr.pub}
                              disabled={addr.bal === 0}
                              color="primary"
                              size="small"
                              checked={this.state.selectedAddress === addr.pub}
                              onChange={this.selectAddress}
                            />
                          </TableCell>
                          <TableCell align="right">{addr.bal}</TableCell>
                          <TableCell
                            align="right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {addr.pub}
                          </TableCell>
                          <TableCell align="center">{addr.numRec}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={Math.ceil(this.state.addresses.length / 5)}
                page={this.state.tablePage}
                color="primary"
                className={classes.pagination}
                onChange={this.changePage}
              />
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <div>
        <CardBody>{content}</CardBody>
        <CardFooter className={classes.cardFooter}>
          <Tooltip
            title={
              context.wallet === null || this.state.selectedAddress === ""
                ? "select a FLO address with balance to enable this button."
                : "Submit records to the FLO blockchain"
            }
            placement="right"
          >
            <span>
              <Button
                disabled={
                  context.wallet === null || this.state.selectedAddress === ""
                }
                color="info"
                size="small"
                onClick={this.confirm}
              >
                Submit
              </Button>
            </span>
          </Tooltip>
        </CardFooter>
        <Dialog
          open={this.state.openConfirmation}
          onClose={this.handleCloseConfirmation}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">
            {config.confirmation.title}
          </DialogTitle>
          <DialogContent>
            {config.confirmation.body.map((entry, index) => {
              return (
                <DialogContentText
                  id={`confirmation-dialog-description-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
            <DialogContentText id="confirmation-dialog-address">
              Selected address: {this.state.selectedAddress}
            </DialogContentText>
            {config.confirmation.finalWords.map((entry, index) => {
              return (
                <DialogContentText
                  id={`confirmation-dialog-finalWords-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
            {this.state.donate && (
              <DialogContentText>
                <Checkbox
                  disabled={!this.state.donate}
                  checked={this.state.donateCheck}
                  onChange={this.donationSwitch}
                  name="checkedB"
                  size="small"
                  color="primary"
                ></Checkbox>
                <Typography variant="caption">{config.tip.mainMsg}</Typography>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseConfirmation}
              color="info"
              size="small"
            >
              {config.confirmation.no}
            </Button>
            <Button onClick={this.publish} color="info" size="small" autoFocus>
              {config.confirmation.yes}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.receiptDialog === 1} // {this.state.openConfirmation}
          onClose={this.handleCloseConfirmation}
          aria-labelledby="afterPost-success-dialog-title"
          aria-describedby="afterPost-success-dialog-description"
        >
          <DialogTitle id="afterPost-success-dialog-title">
            {config.afterPost.success.title}
          </DialogTitle>
          <DialogContent>
            {config.afterPost.success.body.map((entry, index) => {
              return (
                <DialogContentText
                  id={`afterPost-success-dialog-description-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
            <DialogContentText id="afterPost-success-dialog-address">
              Selected address: {this.state.selectedAddress}
            </DialogContentText>
            {config.afterPost.success.finalWords.map((entry, index) => {
              return (
                <DialogContentText
                  id={`afterPost-success-dialog-finalWords-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
            <DialogContentText id="afterPost-success-dialog-avatar-address">
              Avatar id: {this.state.avatarOipRef}
            </DialogContentText>
            <DialogContentText id="afterPost-success-dialog-avatar-address">
              Main record id: {this.state.personOipRef}
            </DialogContentText>
            {this.state.donationReceipt !== "" && (
              <DialogContentText id="afterPost-success-dialog-avatar-address">
                Donation id: {this.state.donationReceipt}
              </DialogContentText>
            )}
          </DialogContent>
          <Box display="flex" justifyContent="center">
            <DialogActions>
              <Button
                onClick={() => this.handleReceipt(0)}
                color="success"
                size="small"
              >
                {config.afterPost.success.button}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Dialog
          open={this.state.receiptDialog === 2} // {this.state.openConfirmation}
          onClose={this.handleCloseConfirmation}
          aria-labelledby="afterPost-fail-dialog-title"
          aria-describedby="afterPost-fail-dialog-description"
        >
          <DialogTitle id="afterPost-fail-dialog-title">
            {config.afterPost.fail.title}
          </DialogTitle>
          <DialogContent>
            {config.afterPost.fail.body.map((entry, index) => {
              return (
                <DialogContentText
                  id={`afterPost-fail-dialog-description-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
            <DialogContentText id="afterPost-fail-dialog-address">
              Selected address: {this.state.selectedAddress}
            </DialogContentText>
            {config.afterPost.fail.finalWords.map((entry, index) => {
              return (
                <DialogContentText
                  id={`afterPost-fail-dialog-finalWords-${index}`}
                  key={index}
                >
                  {entry}
                </DialogContentText>
              );
            })}
          </DialogContent>
          <Box display="flex" justifyContent="center">
            <DialogActions>
              <Button onClick={() => this.handleReceipt(0)} size="small">
                {config.afterPost.fail.button}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    );
  }
}

SectionForm.propTypes = {
  classes: PropTypes.object.isRequired
};

SectionForm.contextType = MnemonicsStoreConsumer;

export default withStyles(styles)(SectionForm);
