import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import TextField from "@material-ui/core/TextField";
import browsePageStyle from "assets/jss/material-kit-react/views/browsePage.jsx";

// Sections for this page

import SectionBrowsegrid from "./Sections/SectionBrowseGrid";

import { config } from "ddx.config.js";

const dashboardRoutes = [];

class BrowsePage extends React.Component {
  constructor() {
    super();
    this.state = { records: [] };
    this.state.search = "";
    this.state.submit = "";
  }

  onChange = e => {
    this.setState({ search: e.target.value });
    // api.getRecords().then(json => this.setState({ records: json }));
  };

  search() {
    this.setState({ submit: this.state.search });
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={config.landing.mainImage}>
          <div className={classes.container}>
            <h3 className={classes.searchh3}>What are you looking for?</h3>
            <GridContainer container justify="center">
              <div className={classes.input} style={{ color: "#FFFFFF" }}>
                <TextField
                  id="filled-full-width"
                  fullWidth
                  variant="filled"
                  color="secondary"
                  InputLabelProps={{
                    className: classes.input,
                    shrink: true
                  }}
                  value={this.state.search}
                  onChange={this.onChange}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      this.search();
                    }
                  }}
                />
                <br />
                {/*<SectionAdvancedsearch></SectionAdvancedsearch>*/}
              </div>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <SectionBrowsegrid searchTerms={this.state.submit} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(browsePageStyle)(BrowsePage);
