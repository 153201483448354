import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import pillsStyle from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.jsx";
import { Grid } from "@material-ui/core";
import SectionCard from "views/Components/Sections/SectionCard.bullet.jsx";
// import Pagination from "components/Pagination/Pagination.jsx";
// import SectionSort from "./SectionSort";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";
import Pagination from "@material-ui/lab/Pagination";
import { Typography } from "@material-ui/core";

import { MnemonicsStoreConsumer } from "../../../MnemonicsStore.jsx";

const api = new OipApi(config.daemonApiUrl);

class SectionPills extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: false,
      records: [],
      searching: false,
      searchTerms: "",
      page: 1,
      totalCount: 0,
      txid: "",
      wallet: null
    };
  }

  componentDidMount() {
    this.setState(
      {
        searching: true,
        wallet: this.context.wallet
      },
      () =>
        api.getRecords().then(json => {
          this.setState({
            records: json.results,
            totalCount: json.total,
            txid: json.results.map(d => d.meta.txid)[0],
            searching: false
          });
        })
    );
  }

  componentDidUpdate() {
    if (this.props.searchTerms !== this.state.searchTerms) {
      this.setState(
        {
          searchTerms: this.props.searchTerms,
          page: 1
        },
        this.updateGrid
      );
    }
  }

  changePage = (e, v) => {
    if (this.state.page !== v) {
      this.setState(
        {
          page: v
        },
        this.updateGrid
      );
    }
  };

  handleChangeSwitch = () => {
    this.setState({ filter: !this.state.filter });
  };

  updateGrid = () => {
    this.setState(
      {
        searching: true,
        records: []
      },
      () =>
        api.getRecords(this.state.searchTerms, this.state.page).then(json => {
          this.setState({
            records: json.results,
            totalCount: json.total,
            txid: json.results.map(d => d.meta.txid)[0],
            searching: false
          });
        })
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            direction="row"
            spacing={2}
            style={{ padding: "20px" }}
          ></Grid>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            spacing={2}
            style={{ padding: "20px" }}
          >
            <Grid alignItems="center" direction="column">
              {" "}
              <Typography>
                {this.state.searching
                  ? "Searching the blockchain..."
                  : this.state.records.length === 0
                  ? "No records were found."
                  : `Records found: ${this.state.totalCount}`}
              </Typography>
            </Grid>
            <Grid direction="column">
              <Pagination
                count={Math.ceil(this.state.totalCount / config.oip.perPage)}
                page={this.state.page}
                style={{ padding: "20px" }}
                color="primary"
                className={classes.pagination}
                onChange={this.changePage}
              />
            </Grid>
          </Grid>
          <div id="navigation-pills">
            <div className={classes.root}>
              <Grid container direction="row" spacing={2}>
                {this.state.records.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <SectionCard data={value} />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container spacing={24}></Grid>
            </div>
          </div>
          <Grid
            container
            alignItems="flex-end"
            justify="center"
            direction="row"
            spacing={2}
            style={{ padding: "20px" }}
          >
            <Pagination
              count={Math.ceil(this.state.totalCount / config.oip.perPage)}
              page={this.state.page}
              color="primary"
              className={classes.pagination}
              onChange={this.changePage}
            />
          </Grid>
        </div>
      </div>
    );
  }
}

SectionPills.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTerms: PropTypes.string
};

SectionPills.contextType = MnemonicsStoreConsumer;

export default withStyles(pillsStyle)(SectionPills);
