import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import aboutPageStyle from "assets/jss/material-kit-react/views/aboutPage.jsx";

// Sections for this page
import SectionFaq from "./SectionFaq";
import AboutTextLeft from "./Sections/AboutTextLeft";
import AboutTextRight from "./Sections/AboutTextRight";
import AboutTextFullwidth from "./Sections/AboutTextFullwidth";

import { config } from "ddx.config.js";

const dashboardRoutes = [];

class AboutPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          className={classes.aboutheader}
          filter
          image={config.landing.mainImage}
        >
          <div className={classes.container}>
            <GridContainer className={classes.aboutmain}>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>{config.about.title}</h1>
                <h4 className={classes.subtitle}>{config.about.main}</h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <div>
              {config.about.sections.map(s => {
                let component;
                switch (s.type) {
                  case "full":
                    component = <AboutTextFullwidth content={s} />;
                    break;
                  case "left":
                    component = <AboutTextLeft content={s} />;
                    break;
                  case "right":
                    component = <AboutTextRight content={s} />;
                    break;
                }
                return component;
              })}
            </div>
            <h2>FAQ</h2>
            <SectionFaq />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(aboutPageStyle)(AboutPage);
