import { OIP } from "js-oip";
import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { display } from "@material-ui/system";

import {
  Box,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

class Basic extends React.Component {
  state = {
    update: false,
    value: "",
    valueList: [],
    valueListNames: []
  };

  updateParent = (name, list = false) => {
    this.setState({ update: !this.state.update }, () => {
      const payload = {
        update: this.state.update
      };
      if (name === "date") {
        console.log(`updating Date: ${this.state.value}`);
        const newDate = new Date(this.state.value).getTime() / 1000 || null;
        console.log(`new date in unix time: ${newDate}`);
        if (newDate) {
          this.setState({ value: newDate }, () => {
            payload[name] = this.state.value;
            this.props.onChangePayload(payload);
          });
        }
      } else {
        payload[name] = list ? this.state.valueList : this.state.value;
        this.props.onChangePayload(payload);
      }
    });
  };

  handleChange = name => event => {
    this.setState({ value: event.target.value }, () => this.updateParent(name));
  };

  handleChangeList = (name, checkOip = false) => event => {
    const input = event.target.value.split("\n");
    while (input.indexOf("") !== -1) {
      const rm = input.indexOf("");
      input.splice(rm, 1);
    }
    this.setState({ valueList: input }, () =>
      checkOip ? this.setNames(name) : this.updateParent(name, true)
    );
  };

  setNames = async name => {
    console.log("setNames");
    const names = [];
    const ids = this.state.valueList;
    console.log(ids);
    for (let id of ids) {
      const name = await this.oip2name(id);
      if (name !== "") {
        names.push(name);
      }
    }
    this.setState({ valueListNames: names }, () =>
      this.updateParent(name, true)
    );
  };

  oip2name = id => {
    const oip = new OipApi();
    console.log(id);
    return oip.getRecord(id).then(record => {
      console.log(record);
      if (record.results.length === 1 && record.results[0].meta.txid === id) {
        const name =
          record.results[0].record.details[config.cardInfo.name.tmpl][
            config.cardInfo.name.name
          ];
        console.log(`record: ${JSON.stringify(name)}`);
        return name;
      }
      console.log(`no record found!`);
      return "";
    });
  };

  renderNameField = () => {
    const { classes, label } = this.props;
    return (
      <TextField
        id="standard-name"
        label={label || "Name"}
        value={this.state.name}
        fullWidth
        onChange={this.handleChange("name")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderDateField = () => {
    const { classes, label } = this.props;
    return (
      <TextField
        id="standard-date"
        label={label || "Date"}
        type="date"
        defaultValue=""
        fullWidth
        onChange={this.handleChange("date")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderDescriptionField = () => {
    const { classes, label } = this.props;
    return (
      <TextField
        id="standard-name"
        label={label || "Description"}
        value={this.state.description}
        multiline
        fullWidth
        onChange={this.handleChange("description")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderNoteFieldList = () => {
    const { classes, label } = this.props;
    return (
      <TextField
        id="standard-note-list"
        label={label || "Notes"}
        value={this.state.noteList}
        fullWidth
        multiline
        onChange={this.handleChangeList("noteList")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderTagFieldList = () => {
    const { classes, label } = this.props;
    return (
      <TextField
        id="standard-tag-list"
        label={label || "Tags"}
        value={this.state.noteList}
        fullWidth
        multiline
        onChange={this.handleChangeList("tagList")}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  render() {
    const { component } = this.props;
    switch (component) {
      case "name":
        return this.renderNameField();
      case "date":
        return this.renderDateField();
      case "description":
        return this.renderDescriptionField();
      case "noteList":
        return this.renderNoteFieldList();
      case "tagList":
        return this.renderTagFieldList();
    }
  }
}

Basic.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePayload: PropTypes.func,
  component: PropTypes.string,
  label: PropTypes.string
};

export default withStyles(styles)(Basic);
